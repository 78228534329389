

import FondoAuthRsAgency from '../assets/images/logos/rs_agency/logo1.png';
import LogoRsAgency from '../assets/images/logos/rs_agency/logo1.png';
import LogoMobileRsAgency from '../assets/images/logos/rs_agency/logo1.png';
import FaviconRsAgency from '../assets/images/logos/rs_agency/favicon.ico';

import FondoAuthGassapp from '../assets/images/logos/gassapp/logo1.png';
import LogoGassapp from '../assets/images/logos/gassapp/logo1.png';
import LogoMobileGassapp from '../assets/images/logos/gassapp/logo2.png';
import FondoLoginGassapp from '../assets/images/logos/gassapp/fondo_login.png';
import LogoLoginGassapp from '../assets/images/logos/gassapp/logo_login.png';
import FaviconGassapp from '../assets/images/logos/gassapp/favicon.ico';

import FondoAuthDataprocess from '../assets/images/logos/dataprocess/logo1.png';
import LogoDataprocess from '../assets/images/logos/dataprocess/logo1.png';
import LogoMobileDataprocess from '../assets/images/logos/dataprocess/logo1.png';
import FaviconDataprocess from '../assets/images/logos/dataprocess/favicon.ico';

import FondoAuthIpusa from '../assets/images/logos/ipusa/logo.png';
import LogoIpusa from '../assets/images/logos/ipusa/logo.png';
import LogoMobileIpusa from '../assets/images/logos/ipusa/logo.png';
import FaviconIpusa from '../assets/images/logos/ipusa/favicon.ico';

import FondoAuthOmnix from '../assets/images/logos/omnix/logo.png';
import LogoOmnix from '../assets/images/logos/omnix/logo.png';
import LogoMobileOmnix from '../assets/images/logos/omnix/logo.png';
import FaviconOmnix from '../assets/images/logos/omnix/favicon.ico';

import FondoAuthSunGrow from '../assets/images/logos/sungrow/logo.jpg';
import LogoSunGrow from '../assets/images/logos/sungrow/logo.jpg';
import LogoMobileSunGrow from '../assets/images/logos/sungrow/logo.jpg';
import FaviconSunGrow from '../assets/images/logos/sungrow/favicon.ico';

import FondoAuthWitMakers from '../assets/images/logos/witmakers/logo.jpg';
import LogoWitMakers from '../assets/images/logos/witmakers/logo.jpg';
import LogoMobileWitMakers from '../assets/images/logos/witmakers/logo.jpg';
import FaviconWitMakers from '../assets/images/logos/witmakers/favicon.ico';

import FondoAuthTcbDrones from '../assets/images/logos/tcbdrones/logo.jpg';
import LogoTcbDrones from '../assets/images/logos/tcbdrones/logo.jpg';
import LogoMobileTcbDrones from '../assets/images/logos/tcbdrones/logo.jpg';
import FondoLoginTcbDrones from '../assets/images/logos/tcbdrones/fondo.jpeg';
import FaviconTcbDrones from '../assets/images/logos/tcbdrones/favicon.ico';

import FondoAuthBravat from '../assets/images/logos/bravat/logo.png';
import LogoBravat from '../assets/images/logos/bravat/logo.png';
import LogoMobileBravat from '../assets/images/logos/bravat/logo.png';
import FaviconBravat from '../assets/images/logos/bravat/favicon.ico';

import FondoAuthCecat from '../assets/images/logos/cecat/fondo.png';
import LogoCecat from '../assets/images/logos/cecat/logo.png';
import LogoMobileCecat from '../assets/images/logos/cecat/logo.png';
import FaviconCecat from '../assets/images/logos/cecat/favicon.ico';

import FondoAuthInfiny from '../assets/images/logos/infinytask/background.jpg';
import LogoInfiny from '../assets/images/logos/infinytask/Infinytask1.png';
import LogoInfiny2 from '../assets/images/logos/infinytask/Infinytask2.png';
import LogoMobileInfiny from '../assets/images/logos/infinytask/Infinytask3.png';
import FaviconInfiny from '../assets/images/logos/infinytask/favicon.ico';

import FondoAuthGrupoNordeste from '../assets/images/logos/gruponordeste/logo.png';
import LogoGrupoNordeste from '../assets/images/logos/gruponordeste/logo.png';
import LogoMobileGrupoNordeste from '../assets/images/logos/gruponordeste/logo.png';
import FaviconGrupoNordeste from '../assets/images/logos/gruponordeste/favicon.ico';

import iconUser from '../assets/images/icons/icono_usuario.png';
import Error500 from '../assets/images/error-500.png';
import Error404 from '../assets/images/error-404.png';
import Audio from '../assets/images/icons/audio.png';
import Excel from '../assets/images/icons/excel.png';
import Image from '../assets/images/icons/image.png';
import Pdf from '../assets/images/icons/pdf.png';
import PowerPoint from '../assets/images/icons/powerPoint.png';
import Video from '../assets/images/icons/video.png';
import Word from '../assets/images/icons/word.png';
import Blank from '../assets/images/icons/blank.png';
import construction from '../assets/images/underconstruction.png';
import pinMap from '../assets/images/pin_map.png';


export const Imagesdata = (data) => {

    const img = { 
      iconUser,
      FondoAuthRsAgency,
      LogoRsAgency,
      LogoMobileRsAgency,
      FaviconRsAgency,
      FondoAuthGassapp,
      LogoGassapp,
      LogoMobileGassapp,
      FondoLoginGassapp,
      LogoLoginGassapp,
      FaviconGassapp,
      FondoAuthDataprocess,
      LogoDataprocess,
      LogoMobileDataprocess,
      FaviconDataprocess,
      FondoAuthIpusa,
      LogoIpusa,
      LogoMobileIpusa,
      FaviconIpusa,
      FondoAuthOmnix,
      LogoOmnix,
      LogoMobileOmnix,
      FaviconOmnix,
      FondoAuthSunGrow,
      LogoSunGrow,
      LogoMobileSunGrow,
      FaviconSunGrow,
      FondoAuthWitMakers,
      LogoWitMakers,
      LogoMobileWitMakers,
      FaviconWitMakers,
      FondoAuthTcbDrones,
      LogoTcbDrones,
      LogoMobileTcbDrones,
      FondoLoginTcbDrones,
      FaviconTcbDrones,
      FondoAuthBravat,
      LogoBravat,
      LogoMobileBravat,
      FaviconBravat,
      FondoAuthCecat,
      LogoCecat,
      LogoMobileCecat,
      FaviconCecat,
      FondoAuthInfiny,
      LogoInfiny,
      LogoMobileInfiny,
      FaviconInfiny,
      LogoInfiny2,
      FondoAuthGrupoNordeste,
      LogoGrupoNordeste,
      LogoMobileGrupoNordeste,
      FaviconGrupoNordeste,

      Error500,
      Error404,
      Audio,
      Excel,
      Image,
      Pdf,
      PowerPoint,
      Video,
      Word,
      Blank,
      construction,
      pinMap
    }

    return img[data];
}